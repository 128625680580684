import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];

  static values = {
    klass: String,
  }

  remove() {
    this.elementTarget.classList.remove(this.klassValue);
  }
}
